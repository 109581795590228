/* Customizations */

// Fonts
$font-family-sans-serif:
  komet,
  system-ui,
  -apple-system,
  'Segoe UI',
  Roboto,
  'Helvetica Neue',
  'Noto Sans',
  'Liberation Sans',
  Arial,
  sans-serif,
  'Apple Color Emoji',
  'Segoe UI Emoji',
  'Segoe UI Symbol',
  'Noto Color Emoji' !default;
$font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace !default;
p {
  letter-spacing: -0.02em;
}

// Colors
$primary: #00ffaa;
$secondary: #f2eee5;

// Body
$body-bg: #202020;
$body-color: #fff;

// H1-H6
$headings-color: $primary;
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700 !important;
  text-transform: uppercase;
}
h2 {
  font-size: calc(1rem * 4) !important;
  color: transparent !important;
  -webkit-text-stroke: 2px $primary;
  padding-bottom: 1rem;
}

// Links
$link-hover-color: $secondary;
$link-decoration: none;

// Images
$thumbnail-border-color: darken(#fff, 60%);

// Main CTA
.cta-main {
  color: $primary;
  cursor: pointer;
  font-size: large;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  display: inline-block;
  transition: color 0.3s ease;
  &:hover {
    color: $secondary;
  }
  @media (min-width: 576px) {
    font-size: x-large;
  }
}

// Header & Nav
.header {
  .logo {
    fill: $primary;
    width: 24px;
  }
  a {
    text-align: right;
    text-transform: uppercase;
    font-size: calc(1rem * 1.2);
    font-weight: 700;
    color: $primary;
    &:hover {
      color: $secondary !important;
    }
    .social-icon {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
}
.navbar {
  transition: background-color 0.5s ease;
  button.navbar-toggler:focus {
    box-shadow: none;
  }
}

.navbar.scrolled {
  background-color: rgba(0, 0, 0, 0.8);
  box-shadow: 0px 0px 1px 0px $primary;
}
.navbar.expanded {
  background-color: rgba(0, 0, 0, 0.8);
}
.nav-link.active {
  color: $primary !important;
}
$navbar-light-icon-color: $primary;
$navbar-light-toggler-border-color: $primary;

// Intro-video
.intro-container {
  padding: 0 !important;
  margin: 0 !important;
  width: 100vw;
  overflow: hidden;

  .intro-video {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    .loading {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
    }
    .player {
      z-index: 1;
    }
    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(194, 141, 95, 0.2);
      z-index: 3;
    }
    .logo {
      color: $primary;
      position: absolute;
      top: 0;
      width: 50%;
      max-width: 1000px;
      height: 100%;
      z-index: 4;
      @media (max-width: 768px) {
        width: 80%;
      }
    }
  }
  .video-react-button {
    display: none !important;
  }
}

// Live Dates
.live-dates {
  border: none;
  th,
  td {
    background-color: transparent !important;
    border: none;
    padding-bottom: 1rem !important;
    vertical-align: middle;
  }
  .date {
    padding-left: 0;
    font-weight: 700;
    font-size: x-large;
    color: $primary;
  }
  .venue {
    font-size: large;
    text-transform: uppercase;
  }
  .lineup {
    font-size: medium;
  }
  .tickets {
    text-align: right;
    text-transform: uppercase;
    padding-right: 0;
    .btn-primary {
      font-weight: 700;
      font-size: large;
      &:hover {
        background-color: $secondary;
      }
    }
  }
  .no-dates {
    text-align: center;
  }
  .bandsintown-follow {
    color: $secondary;
    text-align: center;
    .icon {
      width: 1.3rem;
      height: 1.3rem;
    }
  }
}

// About
.about {
  .lineup {
    font-size: x-large;
    b {
      font-weight: 700;
      text-transform: uppercase;
      color: $primary;
    }
    .logo {
      color: $primary;
    }
  }
}

// Photos
.thumbnail-wrapper {
  position: relative;
  width: 100%;
  padding-bottom: 100%; /* This maintains a square aspect ratio */
  overflow: hidden;
  margin-bottom: 1.5rem;
  @media (min-width: 1200px) {
    margin-bottom: 1.75rem;
  }
}

.thumbnail-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
  border-radius: 0.375rem;
}

/* Additional styling for PhotoSwipe gallery */
.pswp__img {
  object-fit: contain; /* Ensures the image fits within the viewport while maintaining aspect ratio */
}

.pswp__counter {
  color: $primary;
  font-size: calc(1rem * 1.2) !important;
}

.pswp__button {
  color: $primary;
  transition: fill 0.3s ease;
  svg {
    fill: $primary;
    transition: fill 0.3s ease;
  }
}
.pswp__button:hover {
  svg {
    fill: $secondary;
  }
}

// Contact
.form-label {
  color: $primary;
  text-transform: uppercase;
  font-weight: 700;
  font-size: large;
  margin-bottom: 0.1rem !important;
}
.form-control {
  border: 2px solid darken(#fff, 70%) !important;
  &::placeholder {
    color: darken(#fff, 60%) !important;
  }
}
input:focus,
textarea:focus,
select:focus {
  border: 2px solid $primary !important;
}

.send-mail {
  &:hover {
    background-color: $secondary !important;
  }
}

// Footer
.footer {
  text-align: center;
}

/* import video-react styles */
@import '~video-react/styles/scss/video-react';

/* import Adobe Fonts*/
@import 'https://use.typekit.net/kxn0yle.css';

/* import bootstrap to set changes */
@import '~bootstrap/scss/bootstrap';
